/**
 * Reset Styels
 */
 

 * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}


a {
    color: var(--color-heading);
    text-decoration: none;
    outline: none;
}


a:hover,
a:focus,
a:active {
	text-decoration: none;
	outline: none;
	color: var(--blue-two);
}

a:focus {
    outline: none;
}
address {
    margin: 0 0 24px;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}
mark {
    background: var(--color-primary);
    color: #ffffff;
}
code,
kbd,
pre,
samp {
    font-size: var(--font-size-b3);
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    color: var(--color-primary);
}
kbd ,
ins{
    color: #ffffff;
}




pre, 
pre.wp-block-code {
    font-family: "Courier 10 Pitch", Courier, monospace !important;
    font-size: 15px !important;
    margin: 40px 0 !important;
    overflow: auto;
    padding: 20px !important;
    white-space: pre !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    color: var(--color-body) !important;
    background: var(--color-light);
    border-radius: 4px;
}


small {
    font-size: smaller;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

dl {
    margin-top: 0;
    margin-bottom: 10px;
}

dd {
    margin: 0 15px 15px;
}
dt {
    font-weight: bold;
    color: var(--color-heading);
}

menu,
ol,
ul {
    margin: 16px 0;
    padding: 0 0 0 40px;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}
li>ul,
li>ol {
    margin: 0;
}

ol {
    ul {
        margin-bottom: 0;
    }
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
form {
    margin: 0;
}
fieldset {
    border: 1px solid var(--color-border);
    margin: 0 2px;
    min-width: inherit;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    appearance: textfield;
    padding-right: 2px;
    width: 270px;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
caption,
th,
td {
    font-weight: normal;
}
table thead th {
    font-weight: 700;
}

td,
.wp-block-calendar tfoot td {
    border: 1px solid var(--color-border);
    padding: 7px 10px;
}
del {
    color: #333;
}

ins {
    background: rgba(56, 88, 246, 0.6);
    text-decoration: none;
    padding: 0 5px;
}
hr {
    background-size: 4px 4px;
    border: 0;
    height: 1px;
    margin: 0 0 24px;
    opacity: 0.25;
}
table a,
table a:link, 
table a:visited {
    text-decoration: underline;
}

dt {
    font-weight: bold;
    margin-bottom: 10px;
}

dd {
    margin: 0 15px 15px;
}

caption {
    caption-side: top;
}

kbd {
    background: var(--heading-color);
}

dfn,
cite,
em {
    font-style: italic;
}


/* BlockQuote  */
blockquote,
q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

blockquote {
    font-size: var(--font-size-b1);
    font-style: italic;
    font-weight: var(--p-light);
    margin: 24px 40px;
}

blockquote blockquote {
    margin-right: 0;
}

blockquote cite,
blockquote small {
    font-size: var(--font-size-b3);
    font-weight: normal;
}

blockquote strong,
blockquote b {
    font-weight: 700;
}










