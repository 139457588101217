/**************************************
    Default Styles
***************************************/

@import 'bootstrap/dist/css/bootstrap.min.css';
// @import url('../css/bootstrap/bootstrap-utilities.css');
// @import url('../css/bootstrap/bootstrap-grid.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');


@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';



/**************************************
    Theme Styles
***************************************/

@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';


/**************************************
    Elements Styles
***************************************/
@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/events';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';
@import 'elements/slider';


/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';


@import url("https://use.typekit.net/idu3mvc.css");

.event-swiper.swiper-initialized {
    padding-top:20px;
    padding-bottom:9px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 72%, rgba(255,255,255,0) 82%, rgb(160, 165, 168) 94%);
}

@media (max-width:769px) {
    .event-swiper.swiper-initialized {
        background: white;
    }
}

.micronet {
    background:white !important;
    padding:20px;
}