/*--------------------------
    Header Styles  
 --------------------------*/
.logo-dark {
    display: none;
}


.cities {
    display: flex;
    justify-content: space-around;
    list-style: none;
    padding-left:0px;
}

.cities li {
    width:100%; text-align:center; color:black;
    border-right:2px solid black;
    font-family: "century-gothic", sans-serif;
font-size: 12px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0.22em;
text-align: center;

    &:last-of-type {
        border-right:0px;
    }
}


.cities-wrap {
    background:#D6D6D6;
}


@media (max-width:768px) {
    .cities-wrap {
        display:none;
    }
}

.header-default {
    max-width: 100%;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--blue);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;

    .logo {
        padding:10px;
        a {
            display: flex;
            height: 70px;
            line-height: 80px;
            align-items: center;

            img {
                max-height: 105px;
                border-radius: 100%;
                background: var(--blue);
                bottom: -20px;
                position: relative;
            }
        }
    }

    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &.header-relative {
        position: relative;
    }

    .header-right {
        display: flex;
        justify-content: flex-end;
        .header-btn {
            margin-left: 25px;
            @media #{$md-layout} {
                margin-left: 0;
            }
            @media #{$sm-layout} {
                margin-left: 0;
            }
        }
    }

    a,
    button {
        &.btn-default {
            @media #{$sm-layout} {
                height: 33px;
                line-height: 30px;
                padding: 0 9px;
                font-size: 12px;
            }
            &.btn-small {
                @media #{$sm-layout} {
                    height: 33px;
                    line-height: 30px;
                    padding: 0 9px;
                    font-size: 12px;
                }
            }
        }
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background: transparent !important;
        backdrop-filter: inherit;
    }
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;
    background-color:var(--blue);

    @media #{$md-layout} {
        position: relative;
    }

    @media #{$sm-layout} {
        position: relative;
    }

    .header-top-bar {
        background-color: var(--color-primary);

        @media #{$md-layout} {
            background-color: var(--blue);
        }

        @media #{$sm-layout} {
            background-color: var(--blue);
        }
    }

    .header-default {
        background-color: var(--blue);
        background-image: none;
        border-bottom:5px solid white;

        @media #{$md-layout} {
            background-color: var(--blue);
        }

        @media #{$sm-layout} {
            background-color: var(--blue);
        }
    }
}



.hamberger-button {
    border: 0 none;
    color: var(--color-white);
    width:40px;
    height: 40px;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        position: relative;
        z-index: 2;
        stroke-width: 2px;
        stroke: #fff;
    }
}

.header-left-align {
    .mainmenu-nav {
        margin-left: 30px;
    }
}



.rn-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-dark) !important;
    width: 100%;
    animation: headerSticky .95s ease forwards;
    z-index: 9;
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}


.Logo {
    max-width:210px;
    position:relative;
    display: block;
    margin-top:-45px;
    background-color:var(--blue);
}
.header-default.sticky .Logo {
max-width:140px;
margin-top:0px;
}

@media (max-width:768px) {
    .header-default .logo a img {
        max-height:60px;
    }
    .Logo {
        margin-top:0px;
    }
}
