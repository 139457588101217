.slider-wrapper {
    width:100%;
    background-color: white;
}
.slider{
    padding: 50px 0;
}
.slider-origin{
  

    border-radius: 50%;
    display: block;
    margin:auto !IMportant;
    height:100%; width:115px !important;
}
.slider-inner{
    display: block;
    width:100% !Important;
    height:550px !important; margin:auto;
}
.num {position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    FONT-SIZE: 103PX;
    OPACITY: .3;}
.slider-item h2 {FONT-weight:bolder !IMportant; color:white !Important; text-align:center; font-size:18px !Important; 
    top: 12px; position:relative;}
.slider-item p {    text-align: center;
    top: 40px;
    position: relative;
}
.slider-item{
    -webkit-clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
            clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);

    width: 150px;
    height: 150px;
    -webkit-box-shadow: 0 0 3px 1px rgba(0,0,0,0.1);
            box-shadow: 0 0 3px 1px rgba(0,0,0,0.1);
    border-radius: 3px;
    position: absolute;
		cursor: pointer;
    left:41%;
    transform-origin: center 175%;
    -webkit-transform-origin: center 175%;
    -ms-transform-origin: center 175%;
    -moz-transform-origin: center 175%;
    
    -webkit-transition: -webkit-transform 0.5s;
    
    transition: -webkit-transform 0.5s;
    
    -o-transition: transform 0.5s;
    
    transition: transform 0.5s;
    
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -moz-transition: transform 0.5s;

    text-align: left;
    color: #fff;
    padding: 7px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    -moz-user-select: -moz-none;
   -webkit-user-select: none;

   /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
   -ms-user-select: none;
   user-select: none;
}


.slider-item.active h2 {-webkit-filter: drop-shadow(2px 4px 6px black);filter: drop-shadow(2px 4px 6px black);}
.slider-item.active span {-webkit-filter: drop-shadow(2px 4px 6px black);filter: drop-shadow(2px 4px 6px black);}

.slider-item.active{
    background-color: purple;
		cursor: default;
}
.slider-item:not(.active):hover{
	-webkit-filter: brightness(.8);
	        filter: brightness(.8);
}

.slider-item:not(.active){
	-webkit-filter: brightness(.9);
	        filter: brightness(.9);
}


.slider-title {
    text-align:center;
    padding-top:50px;
    margin-top:20px;
}

.slider-title h2 {
    font-size: 60px;
    margin-bottom:0px;
}
.slider-title span {
    font-size:28px;
    font-weight: 500;
}

.slider-text {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
}

.slider-text > div {
    margin-top:-80px;
    display:block;
    position:relative;
}

.slider-text H3 {
    text-transform: capitalize;
    font-size:60px;
    color:var(--blue);
}

.slider-text p {
    font-size:25px;
}

@media (max-width: 768px) {
    .slider-item{

        height:90px !important;
        width:90px !important;
    }
    
    body .slider-inner {height: 370px !important; right:-7px; position:relative;}
    .slider-item {left:35% !important; }
    
    body .slider-item h2 {
        FONT-weight: bolder !IMportant;
        color: #fff !Important;
        text-align: center;
        font-size: 12px !Important;
        top: 8px;
        position: relative;
        line-height: 1 !important;
    }
    
    body .slider-item p {
        text-align: center !important;
        top:25px;
        position: relative;
        font-size: 10px;
        line-height: 1 !important;
    }

    .slider-text H3 {
        font-size:30px;
    }

    .slider-title h2 {
        font-size: 40px;
     
    }
    .slider-title span {
        font-size:20px;
    }
    .slider-title {
        background:white;
        width:100%;
    }
    .slider-text > div {
        margin-top:0px;
    }
    .slider-text {
        background:white;
        padding-top:20px;
        padding-bottom:10px
    }
    .slider-text p {
        font-size:18px;
    }

    .slider-text {
        min-height:250px;
        align-items: flex-start;
    }

    .slider-wrapper .container {
        background-color:#ffffffa3;
    }

}